import { _axios } from '@/utils/axios'

export function getOrderInfo(params) {
  if (params) {
    params.random = Math.random()
  }
  return _axios({
    url: `/orders/${params.orderId}`,
    method: 'get',
    params
  })
}

export function getOrders(params) {
  if (params) {
    params.random = Math.random()
  }
  return _axios({
    url: '/orders',
    method: 'get',
    params
  })
}